<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FPegawaiTable/>
  </v-card>
</template>

<script>
import FPegawaiTable from "@/components/setting/pegawai/FSalesmanTable.vue";

export default {
  components: { FPegawaiTable },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
</style>